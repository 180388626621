<template>
  <v-card
    color="element"
    @click="dialog=true"
    :style="{'border-left': `${categoryColor(item.Category)} solid 5px`}">
    <v-row>
      <v-col cols="2">
        <img class="logo"
          :src="logo(item.Name)"
          :alt="item.name"
        />
      </v-col>
      <v-col cols="5">
        <div class="title">
          {{item.Name}} <span class="ticker">{{item.Ticker}}</span>
        </div>
      </v-col>
      <v-col cols="2.5">
        <div class="price">
          <c-balance-display class="value"
          :inEuro="true" :balance="item.Price" />
        </div>
      </v-col>
      <v-col
        cols="2.5"
        class="change"
        :class="{'green-font-primary': item.ChangePercentage > 0,
                'red-font-primary': item.ChangePercentage <= 0}">
        <div class="relative-change">
          {{ Math.abs(item.ChangePercentage) }} %
        </div>
        <div class="absolute-change">
          €{{ Math.abs(Math.floor(item.ChangePercentage * item.Price) / 100) }}
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import cBalanceDisplay from '@/components/Shared/cBalanceDisplay.vue';

export default {
  name: 'InvestAssetCard',
  props: {
    item: {
      type: Object,
    },
  },
  components: {
    cBalanceDisplay,
  },
  computed: {
    ...mapGetters([
      'logo',
      'categoryColor',
    ]),
  },
  methods: {
    closeModal() {
      this.$router.push({ name: 'Portfolio' });
      this.dialog = false;
    },
  },
};
</script>

<style scoped lang="scss">
.logo {
  height: 25px;
  border-radius: 50%;
}

.title {
  font-weight: bold;
  .ticker {
    font-weight: 100;
    font-size: .8rem;
  }
}

.price {
  font-size: 1.1rem;
  font-weight: bold;
  text-align: right;
}

.change {
  text-align: right;
  .relative-change {
    font-weight: bold;
  }

  .absolute-change {
    font-size: 0.8rem;
  }
}
</style>
